<template>
  <div class="file-detail">
    <van-row>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="useName"
            label="文件名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="fileType"
            label="类型"
            width="50">
        </el-table-column>
        <el-table-column
            prop="busgrp"
            label="组办"
            width="80">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="60">
          <template slot-scope="scope">
            <el-button @click="downloadPDF(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </van-row>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getFileInfoByGrp, saveRecord} from '@/api/fileViewer'

export default {
  name: "FileDetail",
  components: {},
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getFileInfoByGrp({
        userNo: this.$store.getters.userId,
        busgrp: this.$route.params.name
      }).then(res => {
        console.log(res)
        this.tableData = res
        Toast.success('加载成功！');
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败！');
      })

    },
    downloadPDF(row) {
      saveRecord({
        userNo: this.$store.getters.userId,
        busgrp: row.busgrp,
        fileName: row.fileName,
        fileType: row.fileType
      }).then(res => {
      })
      // alert("http://bulk-trawind-shipping.oss-cn-shanghai.aliyuncs.com/" + row)
      window.open(row.url + row.fileName)
    }
  }
}
</script>

<style scoped>
.file-detail {
  padding: 5px;
  background-color: #FFFFFF
}

</style>